.MuiAppBar-colorPrimary {
  background-color: #0d47a1 !important;
  color: #fff !important;
}

.title {
  color: #fff;
  text-decoration: none;
  line-height: 1;
  
  width: 170px;
  display: inline-block;
  margin-top: 8px;
  flex-grow: 1;
  font-weight: '500';
  font-family: 'Bowlby One', cursive;
}
.ppImg{
    float:left;
}
.ppTopText {
    float: left;
    margin-top: 5px;
    font-size: 18px;
    color: #fff;
  }
.ppBottomText {
    color: #f44336;
    float: left;
    font-size: 18px;
  }

  @media screen and (max-width: 630px) {
    h1 span > span {
      display:none;
    }
  }

  @media only screen and (max-width:1024px){
    .ppTopText, .ppBottomText{
      display: none;
    }
    .title{
      width:auto;
    }
  }