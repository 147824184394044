.stripe td {
  border-bottom: 1px solid gray;
}
table {
  border: 1px solid gray;
  margin: 1em;
}
.game_matchup span {
  display: block !important;
}
