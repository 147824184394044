body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.flex {
  display: flex; }

h2.MuiTypography-h2 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em; }

.top {
  margin-bottom: 0; }

.bottom {
  margin-top: 0; }

button span {
  justify-content: left; }

body {
  margin: 0 0 5em 0;
  min-height: 100%;
  background-size: cover;
  background-color: #e1e1e1; }

#bracket {
  font-size: 12px;
  padding: 40px 0;
  margin-top: 40px; }

.container {
  margin: 0 auto;
  display: block;
  display: flex;
  flex-direction: row; }

.split {
  display: block;
  float: left;
  display: flex;
  width: 42%;
  -moz-flex-direction: row;
  flex-direction: row; }

.champion {
  float: left;
  display: block;
  width: 17%;
  flex-direction: row;
  align-self: center;
  margin-top: -15px;
  text-align: center;
  padding: 230px 0\9; }

.champion i {
  color: #a0a6a8;
  font-size: 45px;
  padding: 10px 0; }

.round {
  display: block;
  float: left;
  display: flex;
  flex-direction: column;
  width: 95%;
  width: 30.8333%\9; }

.split-one .round {
  margin: 0 2.5% 0 0; }

.split-two .round {
  margin: 0 0 0 2.5%; }

.matchup {
  margin: 0;
  width: 100%;
  padding: 10px 0;
  transition: all 0.2s; }

.team {
  padding: 0 5px;
  margin: 3px 0;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  position: relative; }

.round-two .matchup {
  margin: 0;
  height: 138px;
  padding: 60px 0; }

.round-three .matchup {
  margin: 0;
  height: 126px;
  padding: 195px 0; }

.round-four .matchup {
  margin: 0;
  height: 278px;
  padding: 447px 0 308px; }

.round-details {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 13px;
  color: #2c7399;
  text-transform: uppercase;
  text-align: center; }

.champion li,
.round li {
  background-color: #fff;
  box-shadow: none; }

li.team {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  opacity: 1; }

.final {
  margin: 1.5em 0; }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.share-icon {
  color: #fff;
  opacity: 0.35; }

.share-icon:hover {
  opacity: 1;
  -webkit-animation: pulse 0.5s;
  animation: pulse 0.5s; }

.date {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: 'Istok Web', sans-serif;
  color: #3f915f; }

hr {
  display: none; }

@media screen and (max-width: 1600px) {
  .container {
    -moz-flex-direction: column;
    flex-direction: column; }
  .split,
  .champion {
    width: 90%;
    margin: 35px 5%; }
  .champion {
    order: 3; }
  .split {
    border-bottom: 1px solid #b6b6b6;
    padding-bottom: 20px; }
  .split-two {
    flex-direction: row-reverse;
    margin: 25px 2.5% 0; }
  hr {
    display: block; }
  .round-one hr {
    margin-top: 21px; }
  .round-two hr {
    margin-top: 6px; }
  .round-three hr {
    margin-top: 21px; }
  .round-four hr {
    margin-top: 20px; } }

.top {
  margin-bottom: 0; }

.bottom {
  margin-top: 0; }

.semis-l,
.semis-r {
  width: 75%;
  margin: 0 auto; }

#first-four {
  display: flex;
  justify-content: space-around; }

@media screen and (max-width: 630px) {
  sup {
    font-size: 74%; }
  button.MuiButton-text-3 {
    font-size: 74%;
    min-width: auto; }
  .split {
    width: 95%;
    margin: 25px 2.5%; }
  .round {
    width: 22%; }
  .round-one hr {
    margin-top: 21px; }
  .round-two hr {
    margin-top: 6px; }
  .round-three hr {
    margin-top: 21px; }
  .round-four hr {
    margin-top: 20px; }
  .split-one {
    margin: 0 5%; }
  .split-two {
    margin: 25px 2.5% 0 0; }
    .split-two .round-one {
      margin-left: 0; }
  #first-four {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row; }
    #first-four h2 {
      display: flex;
      flex-direction: row;
      flex: 1 1; }
    #first-four div {
      display: flex; }
    #first-four .first-four-match {
      flex-direction: column; }
  hr {
    display: block; } }

.first-four-center {
  text-align: center; }

hr {
  width: 115%; }

.Game_stripe__Y_M4K td {
  border-bottom: 1px solid gray; }

table {
  border: 1px solid gray;
  margin: 1em; }

.Game_game_matchup__ES3oN span {
  display: block !important; }

.Team_winner__3UlF1,
.Team_winner__3UlF1 span {
  color: green; }

.Team_loser__2czfo,
.Team_loser__2czfo span {
  color: gray;
  opacity: 0.5; }

.Nav_MuiAppBar-colorPrimary__Nc5O5 {
  background-color: #0d47a1 !important;
  color: #fff !important; }

.Nav_title__bFQnD {
  color: #fff;
  text-decoration: none;
  line-height: 1;
  width: 170px;
  display: inline-block;
  margin-top: 8px;
  flex-grow: 1;
  font-weight: '500';
  font-family: 'Bowlby One', cursive; }

.Nav_ppImg__3GSNS {
  float: left; }

.Nav_ppTopText__1j0Bs {
  float: left;
  margin-top: 5px;
  font-size: 18px;
  color: #fff; }

.Nav_ppBottomText__3_OO1 {
  color: #f44336;
  float: left;
  font-size: 18px; }

@media screen and (max-width: 630px) {
  h1 span > span {
    display: none; } }

@media only screen and (max-width: 1024px) {
  .Nav_ppTopText__1j0Bs, .Nav_ppBottomText__3_OO1 {
    display: none; }
  .Nav_title__bFQnD {
    width: auto; } }

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.fs-pushdown{
  margin-top: 100px !important;
}
