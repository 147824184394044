.top {
  margin-bottom: 0;
}
.bottom {
  margin-top: 0;
}

button {
  span {
    justify-content: left;
  }
}
body {
  margin: 0 0 5em 0;
  min-height: 100%;
  background-size: cover;
  background-color: #e1e1e1;
}

#bracket {
  font-size: 12px;
  padding: 40px 0;
  margin-top: 40px;
}
.container {
  margin: 0 auto;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.split {
  display: block;
  float: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 42%;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  flex-direction: row;
}
.champion {
  float: left;
  display: block;
  width: 17%;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-self: center;
  align-self: center;
  margin-top: -15px;
  text-align: center;
  padding: 230px 0\9;
}
.champion i {
  color: #a0a6a8;
  font-size: 45px;
  padding: 10px 0;
}
.round {
  display: block;
  float: left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 95%;
  width: 30.8333%\9;
}
.split-one .round {
  margin: 0 2.5% 0 0;
}
.split-two .round {
  margin: 0 0 0 2.5%;
}
.matchup {
  margin: 0;
  width: 100%;
  padding: 10px 0;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.team {
  padding: 0 5px;
  margin: 3px 0;
  height: 50px;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}
.round-two .matchup {
  margin: 0;
  height: 138px;
  padding: 60px 0;
}
.round-three .matchup {
  margin: 0;
  height: 126px;
  padding: 195px 0;
}

.round-four .matchup {
  margin: 0;
  height: 278px;
  padding: 447px 0 308px;
}
.round-details {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 13px;
  color: #2c7399;
  text-transform: uppercase;
  text-align: center;
}
.champion li,
.round li {
  background-color: #fff;
  box-shadow: none;
}
li.team {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.final {
  margin: 1.5em 0;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    transform: scale(1.3);
  }

  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

.share-icon {
  color: #fff;
  opacity: 0.35;
}
.share-icon:hover {
  opacity: 1;
  -webkit-animation: pulse 0.5s;
  animation: pulse 0.5s;
}
.date {
  font-size: 10px;
  letter-spacing: 2px;
  font-family: 'Istok Web', sans-serif;
  color: #3f915f;
}
hr {
  display: none;
}
@media screen and (max-width: 1600px) {
  .container {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
  }
  .split,
  .champion {
    width: 90%;
    margin: 35px 5%;
  }
  .champion {
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .split {
    border-bottom: 1px solid #b6b6b6;
    padding-bottom: 20px;
  }
  .split-two {
    flex-direction: row-reverse;
    margin: 25px 2.5% 0;
  }
  hr {
    display: block;
  }
  .round-one{
    hr{
      margin-top:21px;
    }
  }
  .round-two{
    hr{
      margin-top:6px;
    }
  }
  .round-three {
    hr {
      margin-top: 21px;
    }
  }
  .round-four {
    hr {
      margin-top: 20px;
    }
  }
}

.top {
  margin-bottom: 0;
}
.bottom {
  margin-top: 0;
}
.semis-l,
.semis-r {
  width: 75%;
  margin: 0 auto;
}

#first-four {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 630px) {
  sup {
    font-size: 74%;
  }
  button.MuiButton-text-3 {
    font-size: 74%;
    min-width: auto;
  }
  .split {
    width: 95%;
    margin: 25px 2.5%;
  }
  .round {
    width: 22%;
  }
  .round-one{
    hr{
      margin-top:21px;
    }
  }
  .round-two{
    hr{
      margin-top:6px;
    }
  }
  .round-three {
    hr {
      margin-top: 21px;
    }
  }
  .round-four {
    hr {
      margin-top: 20px;
    }
  }
  .split-one {
    margin: 0 5%;
  }
  .split-two {
    margin: 25px 2.5% 0 0;
    .round-one {
      margin-left: 0;
    }
  }

  #first-four {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    h2 {
      display: flex;
      flex-direction: row;
      flex: 1 1;
    }
    div {
      display: flex;
    }

    .first-four-match {
      flex-direction: column;
    }
  }
  hr {
    display: block;
  }
}
.first-four-center {
  text-align: center;
}
hr {
  width: 115%;
}
